* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  background: -webkit-linear-gradient(to right, #4834d4, #341f97);
  background: -webkit-linear-gradient(left, #4834d4, #341f97);
  background: linear-gradient(to right, #4834d4, #341f97);
  background: #03091f;
}
.darker-blue{
  background: linear-gradient(180deg,#240044 0,#0f0240 25%,#400959 40%,#0f0240 65%,#0f0240);
}
.darker{
 background: #03091f;
}
.darker2 {
  background: #090134;
}
.darkest{
  background: #111028
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  line-height: 1.4;
  font-weight: 500;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  outline: none;
}

li {
  list-style: none;
}

p {
  line-height: 1.9;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
.section-header{
  position: relative;
  margin-bottom: 50px
}
.section-title {
  font-size: 44px;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  text-align: center;
  color: #fff;
}
.section-header .desc{
  max-width: 800px;
  text-align: center;
  margin: 0 auto
}
.bg-title {
  margin-bottom: -37px;
  margin-left: -30px;
  font-size: 66px;
  opacity: .04;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
}
.has-print{
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  /*background: url(../img/core-img/shape1.png) no-repeat;*/
}
.header-area .light.classy-nav-container a{
  color: #fff
}
/*
.header-area.sticky .light.classy-nav-container a{
  color: #333
}
*/
.header-area.sticky .classy-navbar-toggler .navbarToggler span{
  background: #333 !important
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #4834d4;
  background: -webkit-linear-gradient(to right, #4834d4, #341f97);
  background: -webkit-linear-gradient(left, #4834d4, #341f97);
  background: linear-gradient(to right, #4834d4, #341f97);
}

.preload-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
  z-index: 100;
}

#dream-load {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2400ms linear infinite;
  animation: dreamrotate 2400ms linear infinite;
  z-index: 999;
}

#dream-load:before {
  content: "";
  position: absolute;
  top: 7.5px;
  left: 7.5px;
  right: 7.5px;
  bottom: 7.5px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2700ms linear infinite;
  animation: dreamrotate 2700ms linear infinite
}

#dream-load:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 1800ms linear infinite;
  animation: dreamrotate 1800ms linear infinite
}

@-webkit-keyframes dreamrotate {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes dreamrotate {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}
.mr-5p {
  margin-right: 5px;
}
.mr-10p {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-100 {
  margin-right: 100px;
}
.no-padding-right{
  padding-right: 0 !important
}
.no-padding-left{
  padding-left: 0 !important
}
@media (max-width: 767px){
  .no-padding-right{
  padding-right: 15px !important
}
  .no-padding-left{
      padding-left: 15px !important
  }
}
.mb-0{
  margin-bottom: 0 !important
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-100 {
  margin-left: 100px;
}
.pt-30{
  padding-top: 30px
}
.pt-60{
  padding-top: 60px
}
@media (max-width: 992px){
  .double-bg{
      background-size: cover !important
  }
  .mt-s{
      margin-top: 30px !important
  }
  .padding-t-md-0{
      padding-top: 0 !important
  }
}
@media (max-width: 767px){
  .mt-x{
      margin-top: 30px !important
  }
  
  .main-ilustration-5{
      height: 65vh !important
  }
}
@media (max-width: 480px){

  .welcome_area.ico {
      height: 100vh !important;
  }
  .main-ilustration-5{
      display: none;
  }
  .integration-text{
      font-size: 12px
  }
  .integration-icon .badge{
      display: none;
  }
  .spons{
      border-top: 1px solid #eee 
  }
  .spons img{
      width: 80%
  }
}

.section-padding-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section-padding-100-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}
.section-padding-0-70 {
  padding-top: 0px;
  padding-bottom: 70px;
}
.section-padding-100-85 {
  padding-top: 100px;
  padding-bottom: 85px;
}
.section-padding-0-100 {
  padding-top: 0px;
  padding-bottom: 100px;
}
.section-before:before{
  content: '';
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  width: 150px;
  /*background: url(../img/core-img/shape1.png) no-repeat;*/
  background-size: contain;
}
.scew-bg{
  /*background: url(../img/svg/bg-header1.svg) no-repeat;*/
  background-size: cover
  
}
.travel-bg{
  /*background: url(../img//bg-img/travel-bg.png) no-repeat;*/
  background-size: cover
  
}
.relative{
  position: relative;
}
.box-shadow {
  -webkit-box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: block;
  top: 0;
}


.section-padding-0-100 {
  padding-top: 0;
  padding-bottom: 100px;
}

.section-padding-100-0 {
  padding-top: 100px;
  padding-bottom: 0;
}

.section-padding-100-50 {
  padding-top: 100px;
  padding-bottom: 50px;
}

.section-padding-0-50 {
  padding-top: 0;
  padding-bottom: 50px;
}

.section-padding-50-0 {
  padding-top: 50px;
  padding-bottom: 0;
}

.section-padding-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.section-padding-0-200 {
  padding-top: 0;
  padding-bottom: 200px;
}

.section-padding-200-0 {
  padding-top: 200px;
  padding-bottom: 0;
}

.section-padding-200-100 {
  padding-top: 200px;
  padding-bottom: 100px;
}

.section-padding-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section-padding-150-0 {
  padding-top: 150px;
  padding-bottom: 0;
}

.section-padding-0-150 {
  padding-top: 0;
  padding-bottom: 150px;
}

.section-padding-50-150 {
  padding-top: 50px;
  padding-bottom: 150px;
}

.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

.section-heading h2 {
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.section-heading > p {
  margin-bottom: 0;
  line-height: 2;
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto;
}

#scrollUp {
  bottom: 130px;
  font-size: 12px;
  line-height: 22px;
  right: 0px;
  width: 100px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  height: 20px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#scrollUp:before {
  position: absolute;
  width: 30%;
  height: 2px;
  background-color: #fff;
  content: "";
  top: 10px;
  right: 100%;
  z-index: -200 !important;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.has-gradient{
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%); 
}
.has-border-top{
  border-top: 1px solid #eee
}
@media (max-width: 992px){
  .has-border-top-md{
      border-top: 1px solid #eee
  }
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.height-400 {
  height: 400px;
}

.height-600 {
  height: 600px;
}

.height-700 {
  height: 700px;
}

.height-800 {
  height: 800px;
}

.height-900 {
  height: 900px;
}

.height-1000 {
  height: 1000px;
}

.dream-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.dream-dots span {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #846FF4 0%, #F17674 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.login-btn {
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 12px !important;
  text-transform: uppercase;
  line-height: 33px;
  padding: 0 20px;
  min-width: 100px;
  color: #fff !important;
  background: rgba(116, 80,254,0.7);
  height: 35px;
  border-radius: 50px;
  letter-spacing: 1px;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff;
  background: #7450fe;
  border-color: #7450fe;
}

.dream-btn {
  position: relative;
  z-index: 1;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  background-image: -webkit-linear-gradient(to right, #846FF4 0%, #F17674 51%, #846FF4 100%);
  background-image: linear-gradient(to right, #846FF4 0%, #F17674 51%, #846FF4 100%);
  border: 1px solid #fff;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.dream-btn:hover {
  background-position: right center;
  color: #fff;
}
.new_Btn{cursor: pointer;}
#upload-btn{display: none;}
.more-btn {
  position: relative;
  z-index: 1;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* background-image: linear-gradient(130deg, #eb3fa9 0%, #00CED1 50%, #eb3fa9 100%) !important; */
  background-color: #ff00ff;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
.more-btn:hover {
  background-position: right center;
  background-image: linear-gradient(130deg, #ff00ff 0%, #00CED1 50%, #ff00ff 100%) !important;
  color: #fff;
}


.b-text {
  color: #272e3a !important;
}
.w-text{
  color: #fff !important
}
.g-text{
  color: #d8d0d0 !important
}
.gray-text{color:#949292 !important}
.bold{
  font-weight: 600 !important
}
.p-text{
  color: blueviolet !important
}
.normal{
  font-weight: 500
}

@media (max-width: 992px){
  .mt-md-30{
      margin-top: 30px
  }
  .mb-md-30{
      margin-bottom: 30px
  }
}


@media (max-width: 767px){
  .mt-sm-30{
      margin-top: 30px
  }
  .mt-smy-0{
      margin-top: 0px
  }
  .mb-sm-30{
      margin-bottom: 30px
  }
  .welcome-content{
      padding-right: 15px
  }
  
}

.demo-item {
  -webkit-box-shadow: 0 2px 28px rgba(0,0,0,.1);
  box-shadow: 0 2px 28px rgba(0,0,0,.1);
  transition: all .3s ease-in;   
  overflow: hidden;
  background: #fff;
  margin-bottom: 30px
}

@media (min-width: 1200px){
  .demo .container {
      max-width: 1280px;
  }
  .hero-section img{
      max-width: 170%;
      margin-left: -100px;
      margin-top: 60px;
      
  }
  .hero-section .curved{
      max-width: 100%;
      margin-top: 30px
  }
}
.demo-item:hover{
  -webkit-box-shadow: 0 1px 28px rgba(255,152,0,.66);
  box-shadow: 0 1px 28px rgba(255,152,0,.66);
      transform: translate(0,-9px);
  -webkit-transform: translate(0,-9px);
}

.preview-link{text-align: center;}
.preview-demo{
  position: relative;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
  font-weight: 700;
  color: #673AB7;
  text-transform: uppercase;
  border: 1px solid #673AB7;
}
.preview-demo i{
  margin-left: 10px
}
.preview-demo:hover{
  color: #333
}

.creator-sec {
  overflow: hidden;
  padding: 30px 20px 10px
}
.highest-bid{
  padding: 15px;
  background: rgba(255,255,255,0.03);
  border-radius: 20px;
}
.dd-bg{
  background: rgba(255,255,255,0.03);
  border-radius: 20px;
}
.author-rank {
  color: #797c88;
  font-weight: 600;
  font-size: 25px;
}
.author-img{margin: 0 15px}
.author-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.collection-item{
  border-radius: 10px;
  background: rgba(255,255,255,0.03);
  display: block;
  overflow: hidden;
  margin-bottom: 30px
}
.collection_info{
  padding: 15px;
  overflow: hidden;
  display: block;
}
.collection_info .more-btn{margin-bottom: 5px}
.service_single_content.collection-item h6{margin-bottom: 5px;margin-top: 5px}
.admire{display: flex;border-top: 1px dotted #565656;padding-top: 20px;}
.admire .adm{width: 50%;font-size: 14px;color: #d8d0d0;}
.admire .adm i{color: #00e0c4;margin-right: 6px;}

.aut-info{
  border: 2px solid #fff;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  margin-top: -35px;
}

.details-list{
  margin: 30px 0
}
.details-list p span{color: #fff;margin-left: 10px}
.bid-price{
  align-items: flex-end;
}
.bid-price p,
.bid-item .author-info p{
  margin-bottom: 0;
  font-size: 12px
} 
.bid-item .author-info{
  flex: auto
}
.biding-end{
  text-align: center;
  margin-top: 30px;
  padding: 20px 15px 10px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}
.detailed-img{
  padding: 5px;
  background: rgba(255,255,255,0.03);
  border-radius: 10px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
}

.card{
  background-color: rgba(5,9,30,0.03);
}

.btn-primary{
  position: relative;
  z-index: 1;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* background-image: linear-gradient(130deg, #ff00ff 0%, #00CED1 50%, #ff00ff 100%) !important; */
  background-color: #ff00ff;
  transition: all 500ms;
}

.btn-primary:hover{
  background-position: right center;
  background-image: linear-gradient(130deg, #ff00ff 0%, #00CED1 50%, #ff00ff 100%) !important;
  color: #fff;
}



/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section{
  position: relative;
  min-height: 700px;
  /*background: url('../img/bg-img/header2.jpg') no-repeat bottom center;*/
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.hero-section.moving{
  overflow: hidden;
  background: url(./assets/img/bg-img/header-1.jpg) no-repeat center top; 
  background-size: cover;
}

@media (min-width: 1200px){

  .hero-section img{
      max-width: 170%;
      margin-left: -100px;
      margin-top: 60px;
      
  }
  .hero-section .curved{
      max-width: 100%;
      margin-top: 30px
  }
}


@media (max-width: 991px){
  .hero-section.moving{
      background-position: left top;
      }
}
@-webkit-keyframes cloudwash {
0% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); 
  }
100% {
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0); 
  } 
}

@keyframes cloudwash {
0% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
100% {
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0); } 
}

@media (max-width: 380px){
  .btn-explore,
  .btn-Collect{
      margin-bottom: 1rem;
      }
}


.gradient-text{
  font-weight: bold;
  display: inline-block;
  /* background-image: linear-gradient(135deg, #00CED1 0%, #F17674 100%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-color: #FF00FF;
}
.gradient-text.blue{
  /* background-image: linear-gradient(35deg, #00CED1 0%, #6078EA 100%); */
}

.welcome_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 900px !important;
}

.hero-content {
  width: 100%;
  height: 100%;
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
  background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
  background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
}

.welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.promo-section {
  margin-bottom: 30px;
}
.special-head{
  color: #28cc8b;
  padding-left: 40px;
  font-size: 18px;
  position: relative;
}
.special-head:before{
  content: '';
  background: #3964f9;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}

.integration-link {
  padding: 8px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  letter-spacing: .58px;
  font-size: 14px;
  color: #333;
  line-height: 24px;
  color: #d5d5ea
}

.integration-link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background : #eee;
  border-radius: 100px;
}
.integration-icon {
  margin: 0 5px 0 0;
}
.integration-icon .badge{
  background: #14cab1;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 100px;
  padding: 6px 13px;
}
.integration-text {
  margin: 0 7px 0 0;
  color: #7e7b7b
}
.integration-link>* {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
}


.has-shadow {
  -webkit-box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
  box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
  border-radius: 10px
}



@media (max-width: 767px){

  .welcome-content{
      padding-right: 15px
  }
  
}

.welcome-content h2 {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 20px;
}
.welcome-content h1 {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 20px;
}

.welcome-content p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
} 



/* ##### 2.0 Header Area CSS ##### */
.nav{transition: all .5s ease-in}
.navbar-brand {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0
}
.lh-55px{line-height: 55px}
.navbar-nav .nav-item .nav-link{
    padding: 20px 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}
.navbar-nav .nav-item .nav-link:hover{color:#ff8abd;}
.navbar-expand-md .navbar-nav .dropdown-menu{
    border-top:3px solid #fed136;
}
.dropdown-item:hover{background-color:#543cbd;color:#fff;}
nav{-webkit-transition: padding-top .3s,padding-bottom .3s;
    -moz-transition: padding-top .3s,padding-bottom .3s;
    transition: padding-top .3s,padding-bottom .3s;
    border: none;
    }
    
 .shrink {
    animation: .3s ease-in-out;
    animation-name: fadeInDown;
    background-color: rgb(215, 223, 239);
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.navbar-white .navbar-brand:hover{color:#fff}
.navbar-dark .navbar-brand:hover{color:#333}

/* mobile view */
@media (max-width:500px)
{
    .navbar{ background: rgb(215, 223, 239);}
    .navbar-nav{
        border-top:1px solid #fed136;
        color:#fff;
        z-index:1;
        margin-top:5px;
    }
    .navbar-nav .nav-item .nav-link{
        padding: 0.7em 1em!important;
        font-size: 100%;
        font-weight: 500;
    }

}
.navbar-dark .navbar-toggler,
.navbar-white .navbar-toggler,
.navbar-cyan .navbar-toggler{
    background: #086bfe ;
    border-color: #333
}
.navbar-white .navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}
@media(max-width: 992px){
    .shrink{animation:none;}
    .login-btn{
        margin-left: 15px !important;
        margin-top: 15px !important
    }
    .navbar-collapse .navbar-nav .nav-item .nav-link{
        padding: 5px 12px;
        color: #333
    }
    .navbar-expand-lg.navbar-dark {background: rgb(215, 223, 239);}
    .navbar-collapse{
        padding-top: 20px;
        background: rgb(215, 223, 239)
    }
}
.navbar-white.shrink .navbar-brand,
.shrink .navbar-nav .nav-item .nav-link{
    color: #333
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav .nav-item .nav-link{color: #333}

.navbar-cyan .navbar-brand,
.navbar-cyan .navbar-nav .nav-item .nav-link{color: #04d0ff}


.bg-warning{
  background-color: white !important;
  /* color: black; */
}

.bg-success{
  background-color: white!important;
  color: black!important;
}

.bg-danger{
  background-color: white!important;
  color: black!important;
}

[data-icon="hourglass"]{
  color: black !important;
}

[data-icon="success"]{
  color: black !important;
}

.my-4 {
  color: black!important;
}